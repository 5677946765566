/**
 * @author Akshay Kumar Singh
 * @file AuditDetails Component
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    verifyAndgenerateReport,
    verifyAndgenerateIntermediateReport
} from '../../redux/actions/common_action';
import { LABLES } from '../../shared/constants/data';
import DetailsRow from './DetailsRow';
import HeaderComponent from '../Common/HeaderComponent';
import { formatDate } from '../../shared/utils/helper.util';
import { encryptData } from '../../shared/utils/crypto.util';
import { spinnerService } from '../../shared/services/spinner.service';
import { CallApi } from '../../shared/services/api.service';
import { getAuditId } from '../../shared/utils/helper.util';
import CONFIG from '../../configuration';
import { setData, getData, removeData } from '../../shared/utils/storage.util';
import ROUTES from '../../shared/constants/routes';
import AlertModal from '../../shared/modals/AlertModal';
import LANGUAGECONST from '../../shared/constants/language';
import { serverCalls } from '../../shared/services/serverCalls.service';
import { downloadAuditImages } from '../../shared/utils/audit.util';
import { CommonModal } from '../../shared/modals/CommonModal';

class AuditDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plantName: '',
            selectedAuditData: '',
            plantDirector: '',
            taskList: [],
            showDelAuditModel: false,
            isDelete: false,
            showConfirmDeleteModal: false,
            taskData: {}
        };
    }

    /**
     * @description This function call once the component load at first time aslo set the state variables and call the
     * task list function
     * @returns Changes in state values i.e. plant name, select audit data and plant director.
     */
    componentDidMount() {
        if (!getData('createAuditFlag') || this.props.location.state.audit_id) {
            const data = this.props.location.state;
            this.setState({
                plantName: data.plantName,
                selectedAuditData: data.selectedAuditData,
                plantDirector: data.plantDirector
            });
            this.gettaskList();
        }
    }

    /**
     * @description This function calls the api to get the tasks.
     */
    gettaskList = async () => {
        let language = this.props?.langData?.language || 'en';
        const audit_id =
            this.props.location.state.selectedAuditData?.audit_id ||
            this.props.location.state.audit_id;
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.FetchingTasks
        );
        await CallApi('POST', CONFIG.taskList, {}, { audit_id }, '', 'taskList', this, language);
        setTimeout(() => {
            this.checkAuditDeletion();
        }, 0);
    };

    /**
     * @description This function handle the response of task list api also set the state variable.
     * @param response Response of the task list api.
     * @returns Changes in state value i.e. task list
     */
    taskList = (response) => {
        if (response.success) {
            this.setState({
                taskList: response.response.tasklist
            });
        }
    };

    /**
     * @description This function used to redirect the route on edit task details page.
     * @param evt Event contain the functions
     * @param taskData Task data
     * @param check This is page
     */
    editTaskDetails = (evt, taskData, page, check) => {
        evt.preventDefault();
        if (check == 2 && taskData.task_status !== 3) {
            const plantName = this.props?.location?.state?.plantName;
            const taskid = taskData?.task_id;
            const auditId =
                this.props?.location?.state?.selectedAuditData?.audit_id ||
                this.props?.location?.state?.audit_id;
            const route = ROUTES.EDITAUDITTASK.replace(
                ':plantName/:auditId/:taskId',
                encryptData(plantName) + '/' + auditId + '/' + taskid
            );
            if (!!route) {
                setData('taskData', taskData);
                this.props.history.push({
                    pathname: route,
                    state: {
                        page: page,
                        selectedTaskData: taskData,
                        plantDirector: this.props.location.state.plantDirector,
                        plantName: plantName,
                        fromEdit: 1,
                        fromAdd: 0
                    }
                });
            }
        } else {
            removeData('taskData');
            if (check == 1) {
                this.props.history.push({
                    pathname: ROUTES.CREATEDAUDIT,
                    state: {
                        fromAdd: this.props.location.state.audit_id ? 2 : 1,
                        createdAuditObj: getData('createdAuditObj'),
                        fromEdit: 0,
                        audit_id: this.props.location.state.audit_id
                    }
                });
            } else {
                if (check == 3) {
                    this.props.history.push({
                        pathname: ROUTES.DRAFTEDAUDIT,
                        state: {
                            fromAdd: 2,
                            createdAuditObj: getData('createdAuditObj'),
                            fromEdit: 0,
                            page: page
                        }
                    });
                }
            }
        }
    };

    /**
     * @description This function calls the api to update the audit.
     */
    submitAudit = () => {
        let language = this.props?.langData?.language || 'en';
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        const dataObj = {
            audit_name: auditData.audit_name || auditData.auditName,
            audit_type: '2',
            audit_id: auditData.audit_id || this.props.location.state.audit_id,
            user_id: getData('loginData').userData.user_id,
            plant_id: auditData.plant_id || auditData.plantId,
            start_date: auditData.start_date || auditData.auditBeginDate,
            end_date: auditData.end_date || auditData.auditEndDate
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.UpdatingAudit
        );
        CallApi('POST', CONFIG.updateAudit, {}, dataObj, '', 'updateAudit', this, language);
    };

    /**
     * @description This function used to handle the response of update audit api.
     * @param response Response of the updated audit api.
     * @returns Changes in state values i.e. task list
     */
    updateAudit = (response) => {
        if (response.success) {
            this.setState({
                taskList: response.response.tasklist
            });
            this.props.history.push(ROUTES.DASHBOARD);
        }
    };

    /**
     * @description This function used to call the api to generate the report.
     * @param e This is event.
     * @param assetId This is Asset ID.
     */
    generateReport = (e, assetId) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        e.preventDefault();
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        let data = {
            audit_id: auditData.audit_id || this.props.location.state.audit_id,
            user_id: getData('loginData').userData.user_id
        };
        const fileName = `Report_${auditData.audit_name}.pptx`;
        verifyAndgenerateReport(
            CONFIG.verifyGenerateReport,
            data,
            assetId,
            lang.STRINGS.GENERATINGREPORT,
            fileName,
            this,
            language
        );
    };

    generateIntermediateReport = async (e, reportsList) => {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let language = this.props?.langData?.language || 'en';
        e.preventDefault();
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        let data = {
            audit_id: auditData.audit_id || this.props.location.state.audit_id,
            user_id: getData('loginData').userData.user_id
        };

        const fileName = `Report_${reportsList.audit_name}.pptx`;
        verifyAndgenerateIntermediateReport(
            CONFIG.generateIntermediateReport,
            data,
            lang.STRINGS.GENERATINGINTERMEDIATEREPORT,
            fileName,
            this,
            language
        );
    };

    generateIntermediateReport = async (e, reportsList) => {
        e.preventDefault();
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        let data = {
            audit_id: auditData.audit_id || this.props.location.state.audit_id,
            user_id: getData('loginData').userData.user_id
        };

        const fileName = `Report_${reportsList.audit_name}.pptx`;
        verifyAndgenerateIntermediateReport(
            CONFIG.generateIntermediateReport,
            data,
            'Generating Intermediate Report',
            fileName,
            this
        );
    };

    confirmDeleteTask = (evt, taskData) => {
        evt.preventDefault();
        this.setState({
            showConfirmDeleteModal: true,
            taskData
        });
    };
    /**
     * @description This function used to delete the task by calling the api.
     * @param evt Event use to default prevent
     * @param taskData This is task data.
     */
    //Handles deleting task
    deleteTask = () => {
        this.setState({
            showConfirmDeleteModal: false
        });
        const { taskData } = this.state;
        let language = this.props?.langData?.language || 'en';
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        const deleteObj = {
            asset_type_id: getAuditId(taskData.task_name),
            audit_id: taskData.audit_id,
            task_id: taskData.task_id,
            audit_type: auditData.status
        };
        spinnerService.show(
            'Oval',
            LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.DeletingTask
        );
        CallApi('POST', CONFIG.deleteTask, {}, deleteObj, '', 'deleteTaskData', this, language);
    };

    /**
     * @description This function used to handle the response of delete task api.
     * @param response Response structure received from delete task api.
     */
    deleteTaskData = (response) => {
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        if (response.success) {
            AlertModal.showAlert(
                LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS.TaskDeletedSuccess,
                'delete'
            );
        }
    };

    checkAuditDeletion = () => {
        const auditData = this.props.location.state.audit_id
            ? this.props.location.state.createdAuditObj
            : this.state.selectedAuditData;
        if (auditData.status === 1 && this.state.taskList.length === 0) {
            this.setState({
                showDelAuditModel: true
            });
        }
    };

    componentWillUnmount() {
        AlertModal.hideAlert();
    }

    render() {
        const redirect = { editTask: this.editTaskDetails };
        const deleteData = { deleteTask: this.confirmDeleteTask };
        const activeTab = getData('checkPage') || 1;
        const { showDelAuditModel } = this.state;
        const { showConfirmDeleteModal } = this.state;
        return (
            <main>
                <HeaderComponent
                    history={this.props.history}
                    page={this.props.location.state?.page || 1}
                    language={this.props.langData}
                />
                <CommonModal
                    showModel={showConfirmDeleteModal}
                    showCancel={true}
                    cancelAction={() =>
                        this.setState({
                            showConfirmDeleteModal: false
                        })
                    }
                    showOk={true}
                    okAction={this.deleteTask}
                    msg={
                        LANGUAGECONST[this.props?.langData?.langType || 'ENG'].STRINGS
                            .ConfirmTaskDelete
                    }
                    langData={this.props?.langData}
                />
                <div className="main_cntnt_wrap">
                    <div className="container">
                        <div className="in-progress-audit">
                            <span></span>
                        </div>
                        <div className="plant-summary-wrapper">
                            <div className="plant-summary">
                                <ul id="">
                                    <li>
                                        <small>
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.Plant
                                            }{' '}
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? this.state.selectedAuditData.plant_name
                                                : this.props.history.location.state.createdAuditObj
                                                      .plantname}
                                        </strong>
                                    </li>
                                    <li>
                                        <small>
                                            {' '}
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.AuditBeginDate
                                            }
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? formatDate(
                                                      this.state.selectedAuditData.start_date
                                                  )
                                                : formatDate(
                                                      this.props.history.location.state
                                                          .createdAuditObj.auditBeginDate
                                                  )}
                                        </strong>
                                    </li>
                                    <li>
                                        <small>
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.PlantDirector
                                            }{' '}
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? this.state.selectedAuditData.plant_director
                                                : this.props.history.location.state.createdAuditObj
                                                      .plantDirector}
                                        </strong>
                                    </li>
                                    <li>
                                        <small>
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.AuditEndDate
                                            }{' '}
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? formatDate(this.state.selectedAuditData.end_date)
                                                : formatDate(
                                                      this.props.history.location.state
                                                          .createdAuditObj.auditEndDate
                                                  )}
                                        </strong>
                                    </li>
                                    <li>
                                        <small>
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.AuditName
                                            }
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? this.state.selectedAuditData.audit_name
                                                : this.props.history.location.state.createdAuditObj
                                                      .auditName}
                                        </strong>
                                    </li>
                                    <li>
                                        <small>
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.LastAuditUpdated
                                            }{' '}
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? formatDate(
                                                      this.state?.selectedAuditData?.updated_on
                                                  )
                                                : formatDate(
                                                      this.props.history.location.state
                                                          .createdAuditObj.lastAuditDate
                                                  )}
                                        </strong>
                                    </li>
                                    <li>
                                        <small>
                                            {
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].LABLES.AuditCategory
                                            }{' '}
                                            :
                                        </small>
                                        <strong>
                                            {' '}
                                            {!getData('createAuditFlag')
                                                ? this.state?.selectedAuditData?.category
                                                : this.props.history.location.state.createdAuditObj
                                                      .category}
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                            <div className="tasks-tabs add-task-outer">
                                <div className="table-responsive">
                                    <table className="add-task-tble">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Auditor
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Reviewer
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.AuditType
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Area
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Asset
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Activity
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.EstimateDate
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        LANGUAGECONST[
                                                            this.props?.langData?.langType || 'ENG'
                                                        ].LABLES.Status
                                                    }
                                                </th>
                                                {this.props.location.state?.page !== 4 && (
                                                    <th>
                                                        {
                                                            LANGUAGECONST[
                                                                this.props?.langData?.langType ||
                                                                    'ENG'
                                                            ].LABLES.Action
                                                        }
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* when data is present iterate rows */}
                                            {this.state?.taskList?.map((data, index) => (
                                                <DetailsRow
                                                    taskListData={data}
                                                    handleEdit={redirect}
                                                    handleDelete={deleteData}
                                                    key={index}
                                                    history={this.props.history}
                                                />
                                            ))}
                                            {/* end when data is present iterate rows */}
                                        </tbody>
                                    </table>
                                </div>
                                {/* else add task button */}
                                {!!getData('createAuditFlag') ? (
                                    <div className="add-task-outer">
                                        <center>
                                            <a
                                                href="!#"
                                                onClick={(evt) =>
                                                    this.editTaskDetails(
                                                        evt,
                                                        {},
                                                        getData('checkPage'),
                                                        1
                                                    )
                                                }>
                                                <figure id="">
                                                    <img
                                                        src={require('../../assets/images/plus-button-icon.png')}
                                                        alt="add-icon"
                                                        id=""
                                                    />
                                                    <figcaption>
                                                        {
                                                            LANGUAGECONST[
                                                                this.props?.langData?.langType ||
                                                                    'ENG'
                                                            ].LABLES.AddAuditArea
                                                        }
                                                    </figcaption>
                                                </figure>
                                            </a>
                                        </center>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {/* end else add task button */}
                            </div>
                        </div>
                        {this.props.location.state.page != 4 &&
                        this.props.location.state.page != 3 ? (
                            <div className="submition-outer">
                                {!!!getData('createAuditFlag') ? (
                                    <button
                                        id=""
                                        className="btn-scndry"
                                        onClick={(evt) =>
                                            this.editTaskDetails(evt, {}, getData('checkPage'), 3)
                                        }>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.AddAuditArea
                                        }
                                    </button>
                                ) : (
                                    ''
                                )}
                                <button
                                    type="button"
                                    onClick={() => this.submitAudit()}
                                    id=""
                                    className="btn-scndry">
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.Submit
                                    }
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="submition-outer">
                            {activeTab == 3 ? (
                                <>
                                    {' '}
                                    <button
                                        id=""
                                        className="btn-scndry"
                                        onClick={(evt) =>
                                            this.editTaskDetails(evt, {}, getData('checkPage'), 3)
                                        }>
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.AddAuditArea
                                        }
                                    </button>
                                    <button
                                        type="button"
                                        onClick={(e) =>
                                            this.generateIntermediateReport(
                                                e,
                                                this.props.location.state.selectedAuditData.audit_id
                                            )
                                        }
                                        className="btn-scndry">
                                        {
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .LABLES.GenerateIntermediateReport
                                        }
                                    </button>{' '}
                                </>
                            ) : (
                                ''
                            )}
                            {activeTab != 1 ? (
                                <button
                                    type="button"
                                    onClick={(e) =>
                                        this.generateReport(
                                            e,
                                            this.props.location.state.selectedAuditData.audit_id
                                        )
                                    }
                                    className="btn-scndry">
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .LABLES.GenerateReport
                                    }
                                </button>
                            ) : (
                                ''
                            )}
                            {activeTab != 1 && (
                                <button
                                    type="button"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        const hasImages =
                                            this.state.taskList.find(
                                                (task) => task.images === 1
                                            ) === undefined
                                                ? false
                                                : true;
                                        if (!hasImages) {
                                            AlertModal.showAlert(
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].STRINGS.DOWNLOADING_IMAGES_NONE
                                            );
                                            return;
                                        }
                                        AlertModal.showAlert(
                                            LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                                .STRINGS.DOWNLOADING_IMAGES
                                        );
                                        const downloadImagesRes = await downloadAuditImages(
                                            this.props.location.state.selectedAuditData.audit_id,
                                            this.props?.langData?.langType || 'en',
                                            this.props.location.state.audit_id
                                                ? this.props.location.state.createdAuditObj
                                                      .audit_name ||
                                                      this.props.location.state.createdAuditObj
                                                          .auditName
                                                : this.state.selectedAuditData.audit_name ||
                                                      this.state.selectedAuditData.auditName,
                                            getData('loginData').userData.email,
                                            getData('loginData').userData.first_name
                                        );
                                        if (!downloadImagesRes.images) {
                                            AlertModal.showAlert(
                                                LANGUAGECONST[
                                                    this.props?.langData?.langType || 'ENG'
                                                ].STRINGS.DOWNLOADING_IMAGES_NONE
                                            );
                                        }
                                    }}
                                    className="btn-scndry">
                                    {
                                        LANGUAGECONST[this.props?.langData?.langType || 'ENG']
                                            .BUTTONS.DOWNLOAD_AUDIT_IMAGES
                                    }
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    const data = state.data;
    return data;
};

export default connect(mapStateToProps)(AuditDetailsComponent);
