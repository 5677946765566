/**
 * @author Akshay Kumar Singh
 * @file CreateAudit Modal
 * @flow
 */
import React, { Component } from 'react';
import { LABLES } from '../constants/data';
import Modal from 'react-responsive-modal';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-datepicker';
import { getData, setData, removeData } from '../utils/storage.util';
import ROUTES from '../constants/routes';
import LANGUAGECONST from '../constants/language';
import CONFIG from '../../configuration/index';

/*
 * declare validation rules
 * */
const validationRules = {
    facility: 'required',
    auditBeginDate: 'required',
    plantDirector: 'required',
    auditEndDate: 'required',
    lastAuditedDate: 'required',
    auditName: 'required',
    category: 'required'
};

class CreateAuditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facility: this.props?.plantList[0]?.plant_code,
            plantListData: this.props.plantList,
            plantDirector: this.props.plantList[0]?.plant_director,
            auditEndDate: new Date(+new Date() + 1209600000),
            lastAuditedDate: Date.parse(this.props.plantList[0]?.lastAuditedDate),
            auditName: '',
            auditBeginDate: new Date(),
            plantName: this.props.plantList[0]?.plant_name,
            plantId: '',
            isVlm: '',
            category: CONFIG.auditCategories[0]
        };
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.setData();
        this.setState({});
    }

    /**
     * @description This function set data for selected plant.
     */
    handlePlantChange = (event) => {
        let { name, value } = event.target;
        this.setState(
            {
                [name]: value
            },
            () => {
                this.setData();
            }
        );
    };

    /**
     * @description This function set data for selected audit category.
     */
    handleCategoryChange = (event) => {
        let { name, value } = event.target;
        this.setState(
            {
                [name]: value
            },
            () => {
                this.setData();
            }
        );
    };

    /**
     * @description This function updates plant data for the selected plant.
     */
    setData = () => {
        let date = new Date();
        const codedName =
            '_' +
            (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            (date.getYear() - 100 < 10 ? '0' + (date.getYear() - 100) : date.getYear() - 100) +
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
            (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        this.state.plantListData.forEach((data, index) => {
            if (data.plant_code == this.state.facility) {
                let name = data.plant_code + codedName;
                this.setState(
                    {
                        plantDirector: data.plant_director,
                        auditName: name,
                        plantName: data.plant_name,
                        plantId: data.plant_id,
                        isVlm: data.vlm,
                        lastAuditedDate: !!data.lastAuditedDate
                            ? Date.parse(data.lastAuditedDate)
                            : Date.parse(new Date())
                    },
                    () => {}
                );
            }
        });
    };

    /**
     * @description This function handles all three date values on selecting the begin date.
     */
    handleBeginDate = (event) => {
        this.setState({
            auditBeginDate: event,
            lastAuditedDate: this.state.lastAuditedDate || event,
            auditEndDate: Date.parse(event) + 1209600000
        });
    };

    /**
     * @description This function sets value of auditEndDate variable
     */
    handleEndDate = (event) => {
        this.setState({
            auditEndDate: event
        });
    };

    /**
     * @description This function submit audit details.
     */
    createAudit = () => {
        if (this.validator.allValid()) {
            const createdAuditObj = {
                plantId: this.state.plantId,
                plantCode: this.state.facility,
                plantname: this.state.plantName,
                plantDirector: this.state.plantDirector,
                isVlm: this.state.isVlm,
                auditName: this.state.auditName,
                auditBeginDate: Date.parse(this.state.auditBeginDate),
                auditEndDate: Date.parse(this.state.auditEndDate),
                lastAuditDate: this.state.lastAuditedDate,
                category: this.state.category
            };
            setData('createdAuditObj', createdAuditObj);
            setData('createAuditFlag', true);
            setData('auditName', this.state.auditName);
            setData('plantName', this.state.plantName);
            this.props.history.push({
                pathname: ROUTES.CREATEDAUDITDETAILS,
                state: {
                    page: getData('checkPage'),
                    createdAuditObj: createdAuditObj
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    /**
     * @description This fuunction handles input change
     */
    handleInputChange = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This.function closes create audit modal and hides validation messages.
     * */
    closeCreateAuditModal = (event) => {
        event.preventDefault();
        this.validator.hideMessages();
        this.props.actions.closeCreateAuditModal(event);
    };

    render() {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        return (
            <>
                <Modal
                    open={this.props.isModalOpen}
                    onClose={(evt) => {
                        this.props.actions.closeCreateAuditModal(evt);
                    }}
                    center>
                    <div className="pop-up-bg" id="create-audit-popup">
                        <div className="rqst-accs-popup">
                            <div className="create-audit-outer">
                                <div className="top-cntnt">
                                    <h4> {lang.LABLES.CreateAudit} </h4>
                                    <a
                                        href="!#"
                                        onClick={(evt) => {
                                            this.closeCreateAuditModal(evt);
                                        }}>
                                        <img
                                            src={require('../../assets/images/cross-icon.png')}
                                            alt="cross-icon"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="form-cntnt">
                                <form action="">
                                    <div className="form-row">
                                        <div className="column-three">
                                            <div className="form-group">
                                                <label htmlFor="">{lang.LABLES.Facility}</label>
                                                <select
                                                    name="facility"
                                                    className="form-control"
                                                    onChange={(e) => this.handlePlantChange(e)}
                                                    value={this.state.facility}>
                                                    {this.state.plantListData.map((data, index) => (
                                                        <option value={data.plant_code} key={index}>
                                                            {data.plant_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {this.validator.message(
                                                    'facility',
                                                    this.state.facility,
                                                    validationRules.facility
                                                )}
                                            </div>
                                        </div>
                                        <div className="column-three">
                                            <div className="form-group">
                                                <label htmlFor="">
                                                    {lang.LABLES.PlantDirector}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="plantDirector"
                                                    className="form-control"
                                                    onChange={(e) => this.handleInputChange(e)}
                                                    value={this.state.plantDirector}
                                                    disabled={true}
                                                />
                                                {this.validator.message(
                                                    'plantDirector',
                                                    this.state.plantDirector,
                                                    validationRules.plantDirector
                                                )}
                                            </div>
                                        </div>
                                        <div className="column-three">
                                            <div className="form-group">
                                                <label htmlFor="">{lang.LABLES.AuditName}</label>
                                                <input
                                                    type="text"
                                                    name="auditName"
                                                    className="form-control"
                                                    onChange={(e) => this.handleInputChange(e)}
                                                    value={this.state.auditName}
                                                    disabled={true}
                                                />
                                                {this.validator.message(
                                                    'auditName',
                                                    this.state.auditName,
                                                    validationRules.auditName
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="column-three">
                                            <div className="form-group ">
                                                <label htmlFor="">
                                                    {lang.LABLES.AuditBeginDate}
                                                </label>
                                                <DatePicker
                                                    name="auditBeginDate"
                                                    value={this.state.auditBeginDate}
                                                    className="form-control"
                                                    dateFormat="MM/dd/yyyy"
                                                    minDate={new Date()}
                                                    selected={this.state.auditBeginDate}
                                                    onChange={(event) =>
                                                        this.handleBeginDate(event)
                                                    }
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    locale={this.props?.langData?.language || 'en'}
                                                />
                                                {/* begin date validation */}
                                                {this.validator.message(
                                                    'auditBeginDate',
                                                    this.state.auditBeginDate,
                                                    validationRules.auditBeginDate
                                                )}
                                            </div>
                                        </div>
                                        <div className="column-three">
                                            <div className="form-group">
                                                <label htmlFor="">{lang.LABLES.AuditEndDate}</label>
                                                <DatePicker
                                                    name="auditEndDate"
                                                    value={this.state.auditEndDate}
                                                    className="form-control"
                                                    dateFormat="MM/dd/yyyy"
                                                    minDate={
                                                        Date.parse(this.state.auditBeginDate) +
                                                        86400000
                                                    }
                                                    selected={this.state.auditEndDate}
                                                    disabled={!this.state.auditBeginDate}
                                                    onChange={(event) => this.handleEndDate(event)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    locale={this.props?.langData?.language || 'en'}
                                                />
                                                {this.validator.message(
                                                    'auditEndDate',
                                                    this.state.auditEndDate,
                                                    validationRules.auditEndDate
                                                )}
                                            </div>
                                        </div>
                                        <div className="column-three">
                                            <div className="form-group">
                                                <label htmlFor="">
                                                    {lang.LABLES.LastAuditedDate}
                                                </label>
                                                <DatePicker
                                                    name="lastAuditedDate"
                                                    value={this.state.lastAuditedDate}
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={this.state.lastAuditedDate}
                                                    disabled={true}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    locale={this.props?.langData?.language || 'en'}
                                                />
                                                {this.validator.message(
                                                    'lastAuditedDate',
                                                    this.state.lastAuditedDate,
                                                    validationRules.lastAuditedDate
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="column-three">
                                            <div className="form-group">
                                                <label htmlFor="">
                                                    {lang.LABLES.AuditCategory}
                                                </label>
                                                <select
                                                    name="category"
                                                    className="form-control"
                                                    onChange={(e) => this.handleCategoryChange(e)}
                                                    value={this.state.category}>
                                                    {CONFIG.auditCategories.map((data, index) => (
                                                        <option value={data} key={index}>
                                                            {data}
                                                        </option>
                                                    ))}
                                                </select>
                                                {this.validator.message(
                                                    'category',
                                                    this.state.category,
                                                    validationRules.category
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="crt-odi-btn-otr">
                                        <button
                                            className="btn-prmry"
                                            type="button"
                                            onClick={this.createAudit}>
                                            {' '}
                                            {lang.LABLES.CreateAudit}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* end pop up */}
                </Modal>
            </>
        );
    }
}
export default CreateAuditModal;
